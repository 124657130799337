
<template>
  <div class="fragment">
<!--        :hasCreateLabel="hasCreateLabel"-->
    <ExpressOrderCreationUser
        v-if="!advancedUser && loadedData"
        :ex="ex"
        :selectedCountry="ex.express.delivery.delivery.country"
        @addProduct="addProduct"
        @removeProduct="removeProduct"
        @createExpress="createExpress"
        @createLabel="createLabel"
        @changeHasCreateLabel="changeHasCreateLabel"
    />

    <ExpressOrderCreationAdvancedUser
        v-else-if="loadedData"
        :ex="ex"
        :selectedCountry="ex.express.delivery.delivery.country"
        @addProduct="addProduct"
        @removeProduct="removeProduct"
        @createExpress="createExpress"
        @createLabel="createLabel"
    />


    <AuthorizePaymentPopup
            v-if="authorizePaymentPopup"
            :countMoney="authorizePaySum"
            :authorizePayData="authorizePayData"
            :authorizeError="authorizeError"
            :serverError="serverError"
            :changeCloseToSaveBtn="true"
            @close="()=>{!easyOrder ? $router.push($store.getters.GET_PATHS.expressOrders) : $router.push($store.getters.GET_PATHS.easyOrders)}"
            @submitForm="submitAuthorizeInvoicePay"
    />

    <BraintreePaymentPopup
        v-if="braintreePaymentPopup"
        :amount="authorizePaySum"
        :payData="authorizePayData"
        :invoicePayId="authorizeInvoiceId"
        :changeCloseToSaveBtn="true"
        @submitForm="afterPay"
        @close="()=>{!easyOrder ? $router.push($store.getters.GET_PATHS.expressOrders) : $router.push($store.getters.GET_PATHS.easyOrders)}"
    />

    <ThankYouPopup
            v-if="thankYouPopupPopup"
            @close="() => {$router.push($store.getters.GET_PATHS.expressOrders)}"
            :title="'common_paySuccessTitle'"
            :text="'common_paySuccessText'"
            :linkLastText="'common_paySuccessTextLink'"
            :linkLastTextHref="$store.getters.GET_PATHS.financeMyPayments + '?type=authorize'"
    />
  </div>
</template>

<script>
  import ExpressOrderCreationUser from "./ExpressOrderCreationUser/ExpressOrderCreationUser.vue";
  import ExpressOrderCreationAdvancedUser from "./ExpressOrderCreationAdvancedUser/ExpressOrderCreationAdvancedUser";
  import {Express} from "../../models/Express";
  import {proformMixins} from "../../../../../mixins/proformMixins/proformMixins";
  import {expressMixinsFromCard} from "../../../../../mixins/expressMixins/expressMixin";
  import {
    DHL_DELIVERY_SERVICE,
    EXPRESS_FROM_TRANSACTIONS, NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE
  } from "../../../../../staticData/staticVariables";
  import AuthorizePaymentPopup from "../../../../coreComponents/Popups/AuthorizePaymentPopup/AuthorizePaymentPopup";
  import ThankYouPopup from "../../../../coreComponents/Popups/ThankYouPopup/ThankYouPopup";
  import {paymentBlockFunc} from "../../../../../mixins/dashboardMixins/paymentBlockFunc";
  import BraintreePaymentPopup from "@/components/coreComponents/Popups/BraintreePaymentPopup/BraintreePaymentPopup";

  export default {
    name: "ExpressOrderCreation",
    components: {
      BraintreePaymentPopup,
      ThankYouPopup,
      AuthorizePaymentPopup,
      ExpressOrderCreationUser,
      ExpressOrderCreationAdvancedUser,
    },

    mixins: [
      proformMixins,
      expressMixinsFromCard,
      paymentBlockFunc,
    ],

    props: {
      easyOrder: {
        type: Boolean,
        default: false,
      }
    },

    watch: {
      loadUserRoleAndAuthorizedData: function() {
        this.ex.setSenderInfo(this.loadUserAuthorizedData.user)

        if(this.isCurrentUserRoleUser){
          this.ex.setExpressUser(this.loadUserAuthorizedData.user)
        }
      },

      downloadPermissions() {
        this.checkPermission()
      },
    },

    data() {
      return {
        ex: new Express(),

        // hasCreateLabel: false,

        serverError: false,
        authorizePayData: {},
        authorizePaySum: -1,
        authorizeInvoiceId: -1,
        authorizePaymentPopup: false,
        braintreePaymentPopup: false,
        thankYouPopupPopup: false,
        authorizeError: false,

        createdExpressID: -1,

        advancedUser: false,

        loadedData: false,
      }
    },

    created() {

      if(this.easyOrder) {
        this.ex.express.easyOrderType = true
      }

      if(this.downloadPermissions){
        if(!this.checkPermission()) return
      }

      this.ex.setType(this.$route.params.type)

      /**
       * if NOVAPOSHTA GLOBAL -> send type = only sender
       */
      if (this.ex.express.type === NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id) {
        this.ex.express.sendType = this.ex.express.sendTypes.sender

        this.$router.push(this.$store.getters.GET_PATHS.notFound)
      }

      /**
       * if NOVAPOSHTA GLOBAL and No From Ukraine -> return
       */
      if (this.ex.express.type === NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id && !this.isAdmin && !this.isUserUkraineOrAlternativeContactUkraine) {
        return this.$router.push(this.$store.getters.GET_PATHS.notFound)
      }



      let url = ''
      if(this.$route.query.request_id) {
        url = '?request_id=' + this.$route.query.request_id
      }

      this.$store.dispatch('getExpressCreate', url).then(response => {



        if (this.ex.express.type === DHL_DELIVERY_SERVICE.id || this.$route.query.request_id) {
          if(this.$route.query?.request_id) {
            this.setCreateDataOptions(response)
          }
          let respData = this.getRespData(response)
          if (respData.order_express_country_types && respData.order_express_country_types[DHL_DELIVERY_SERVICE.id] &&
              respData.order_express_country_types[DHL_DELIVERY_SERVICE.id].length > 0) {
            this.ex.express.orderCountryTypesArray = respData.order_express_country_types[DHL_DELIVERY_SERVICE.id]
            this.ex.express.orderCountryType = this.ex.express.orderCountryTypesArray[0].value
          }
        }

        this.loadedData = true
      })




      //default phone number for express create
      this.ex.express.personal.setPersonalPhone('+1-612-203-1280')

      this.advancedUser = this.isExpertUser

      if(this.$route.params.typeTransaction && this.$route.params.id){
        let type = this._.find(EXPRESS_FROM_TRANSACTIONS, {name: this.$route.params.typeTransaction})


        this.ex.setExpressTypeTransaction({
          type: type.value,
          id: this.$route.params.id,
        })

        this.$store.dispatch('getIdentifyExpressOrderFromTrn', {data: {
            "from_model" : this.ex.express.typeTransaction,
            "model_id": this.ex.express.typeTransactionId
          }}).then(response => {
          this.ex.setOrderPrice(
              this.ex.express.type === DHL_DELIVERY_SERVICE.id && this.getRespData(response).paymentTransaction?.warehouse_amount ?
              (+response.data.data.paymentTransaction.warehouse_amount * 0.8).toFixed(2) : this.getRespData(response).orderPrice ? response.data.data.orderPrice : ''
          )

          if (this.ex.express.type === DHL_DELIVERY_SERVICE.id && this.getRespData(response).paymentTransaction?.warehouse_amount) {
            this.ex.setFreightCost(
                (+response.data.data.paymentTransaction.warehouse_amount - (+response.data.data.paymentTransaction.warehouse_amount * 0.8).toFixed(2)).toFixed(2)
            )
          }

          if (this.ex.express.type === DHL_DELIVERY_SERVICE.id && this.getRespData(response).paymentTransaction?.warehouse_amount && this.ex.express.products.length > 0) {
            this.ex.express.products[0].setProductPrice(this.ex.getOrderPrice())
          }

          /**
           * if found internal order and transaction or only transaction -> set transaction data
           */
          if(this.getRespData(response).paymentTransaction){
            this.ex.setExternalTrnInfo(this.getRespData(response).paymentTransaction)
            this.ex.express.paymentTransactionId = this.getRespData(response)?.paymentTransaction?.id || -1
            console.log(this.ex.express.typeTransaction, this.ex.express.paymentTransactionId);
          } else {
            this.$store.dispatch('getImportOrders', this.ex.express.typeTransactionId).then(response => {
              console.log(this.getRespData(response));
              this.ex.setImportOrderInfo(this.getRespData(response))
              // skip transaction if import order not have payment_transaction_id
              if(!this.getRespData(response)?.payment_transaction_id) {
                this.ex.express.typeTransactionId = ''
                if(this.ex.express.type !== NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id) {
                  this.ex.express.sendType = this.ex.express.sendTypes.warehouse
                }
              }

              if(this.getRespData(response)?.id) {
                this.ex.express.orderExternalId = this.getRespData(response)?.id
              }
            }).catch(error => this.createErrorLog(error))

          }

          // this.ex.setExternalTrnInfo(
          //   this.getRespData(response).paymentTransaction !== null ? this.getRespData(response).paymentTransaction : false
          // )
        }).catch(error => this.createErrorLog(error))
      }

      if(this.loadUserAuthorizedData){
        this.ex.setSenderInfo(this.loadUserAuthorizedData.user)

        if(this.isCurrentUserRoleUser){
          this.ex.setExpressUser(this.loadUserAuthorizedData.user)
        }
      }

      this.initializeProductProformArray(this.ex.express.products)
    },

    methods: {

      setCreateDataOptions(response) {
          if (this.getRespData(response)?.orderExpressInstance) {
            let order = this.getRespData(response)?.orderExpressInstance
            console.log(order);
            this.ex.express.delivery.setDeliveryItem({
              address: '',
              address2: '',
              city: order.recipient_city,
              region: order.recipient_region,
              country: {
                id: order.recipientCountry.id,
                label: order.recipientCountry.name,
                code_iso_2: order.recipientCountry.code_iso_2,
              },
              zip: order.recipient_zip_code,
            })
            this.ex.express.packaging.setPackagingItem({
              name: '',
              weight: order.proformPackaging[0].weight,
              height: order.proformPackaging[0].height,
              width: order.proformPackaging[0].width,
              length: order.proformPackaging[0]['length'],
            })
          }
      },

      afterPay() {

        this.$store.dispatch('afterInvoicePayStatusUpdate', this.createdExpressID)
        this.braintreePaymentPopup = false

        this.changeThankYouPopupPopup(true)
      },

      submitAuthorizeInvoicePay(data){
        this.payAuthorizeInvoice({data: data, invoiceId: this.authorizeInvoiceId}).then(status => {
          if(status){
            this.$store.dispatch('afterInvoicePayStatusUpdate', this.createdExpressID)
          }
          this.authorizePaymentPopup = false

          setTimeout(() => {
            this.changeThankYouPopupPopup(true)
          }, 200)
        })
      },

      addProduct(){
        let newIndex = this.ex.addProduct()
        this.initializeProductProformArray(this.ex.express.products, newIndex)
      },

      removeProduct(index) {
        this.ex.removeProduct(index)
      },

      createLabel() {
        this.createExpress({draft: true, createLabel: true})
      },

      createExpress({draft, createLabel = false}) {
        if(this.$store.getters.getIsAdminRights === 'admin') {
          if(!this.checkAdminValidation()) return
        }

        let data = this.ex.prepareCreation(
          draft,
          this.$store.getters.getUserProfile.id,
          false
        )

        let saveType = 'createExpress'

        if(this.ex.express.easyOrderType) {
          saveType = 'createEasyOrdersExpress'
        }

        this.$store.dispatch(saveType, data).then(response => {
          if (this._.has(response, 'data') &&
            response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            let expressId = response.data.data.id
            this.createdExpressID = expressId
            let expressType = this.getExpressTypeById(this.ex.express.type)

            if (createLabel && (this.ex.express.files.length > 0 || this.ex.express.vatNumberFile.getFiles().length > 0)) {
              this.createFiles(expressId).then(() => {
                this.makeLabelHandler(expressId, expressType).then((status) => {
                  if(!this._.has(status, 'statusPaid')){
                    this.goToTable()
                  }
                }).catch(error => this.createErrorLog(error))
              }).catch(error => this.createErrorLog(error))
              return
            }

            if (createLabel) {
              this.makeLabelHandler(expressId, expressType).then((status) => {
                if(!this._.has(status, 'statusPaid')){
                  this.goToTable()
                }
              }).catch(error => this.createErrorLog(error))
              return
            }

            if(this.ex.express.files.length > 0 || this.ex.express.vatNumberFile.getFiles().length > 0) {
              this.createFiles(expressId).then(() => {
                this.goToTable()
              }).catch(error => this.createErrorLog(error))
              return
            }

            this.goToTable()

            this.openNotify('success', 'common_notificationRecordCreated')

          } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors
            this.notifyErrorHelper(errors)
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }).catch(error => this.createErrorLog(error))
      },

      // goToTable() {
      //   /**
      //    * if Easy order
      //    */
      //   if(this.isEasyType) {
      //     this.$router.push(this.$store.getters.GET_PATHS.easyOrders)
      //     return
      //   }
      //
      //   this.$router.push(this.$store.getters.GET_PATHS.expressOrders + this.$store.getters.getExpressFilter)
      // },

    },

  }
</script>

<style scoped>

</style>
